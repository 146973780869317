import { useState, useEffect } from "react";
import { useLocation, NavLink, useParams } from "react-router-dom";
import { Link, Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";

const Breadcrumb = styled(MuiBreadcrumbs)(spacing);

const CapitalizeText = styled.span`
  text-transform: capitalize;
`;

function Breadcrumbs() {
  const location = useLocation();
  const params = useParams();
  const [routePaths, setPath]: any = useState(null);

  useEffect(() => {
    const paths = location.pathname.split("/");

    const arr: any = [];
    paths.forEach((path: any, index: any) => {
      const filterd = Object.values(params).some((i) => i === path);
      if (!filterd) {
        if (index >= 1) {
          if (index !== paths.length - 1) {
            arr.push({
              name: path,
              route: Object.values(params).some((i) => i === paths[index + 1])
                ? `${paths[index - 1]}/${path}/${paths[index + 1]}`
                : `${paths[index - 1]}/${path}`,
            });
          }
        } else {
          arr.push({
            name: "dashboard",
            route: `/dashboard`,
          });
        }
      }
    });
    setPath(arr);
  }, [location.pathname, params]);

  return (
    <Breadcrumb aria-label="Breadcrumb" mt={2}>
      {routePaths?.map((path: any, index: any) =>
        path !== "" ? (
          <Link key={index} component={NavLink} to={`/${path.route}`}>
            <CapitalizeText>{path.name}</CapitalizeText>
          </Link>
        ) : null
      )}
    </Breadcrumb>
  );
}

export default Breadcrumbs;
