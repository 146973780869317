import React from "react";
import { appConfig } from "../config";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

// console.log("process", appConfig);

const url = appConfig.graphqrl;
const httpLink = createHttpLink({
  uri: url,
});
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = sessionStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

let apolloClient;

const createApolloClient = () =>
  new ApolloClient({
    cache: new InMemoryCache(),
    credentials: "include",
    link: authLink.concat(httpLink),
  });

export const initializeApollo = (initialState = null) => {
  const _apolloClient = apolloClient || createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};

//  update Apollo client instance only when cache value has changed
export const useApollo = (initialState) => {
  const store = React.useMemo(
    () => initializeApollo(initialState),
    [initialState]
  );
  return store;
};
