import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { GET_CLIENTS } from "src/pages/clients/graphql/queries.graphql";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

const TableLoading = () => (
  <Box
    sx={{
      width: "100%",
      padding: "5rem",
      display: "flex",
      justifyContent: "center",
    }}
  >
    <CircularProgress />
  </Box>
);

const ClientsTable = () => {
  const { loading, data } = useQuery(GET_CLIENTS);
  const navigate = useNavigate();

  const { clients } = data || {};

  return (
    <>
      <TableContainer>
        {!loading && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Name</TableCell>
                <TableCell>GA Tracking ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clients?.map((client: any) => (
                <TableRow
                  key={client.client_uuid}
                  onClick={() => navigate(client.client_uuid)}
                >
                  <TableCell>
                    <img alt="" src={client.header_logo} />
                  </TableCell>
                  <TableCell>{client.name}</TableCell>
                  <TableCell>{client.ga_tracking_id}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {loading && <TableLoading />}
      </TableContainer>
    </>
  );
};

export default ClientsTable;
