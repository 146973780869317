import { gql } from "@apollo/client";

export const GET_CLIENTS = gql`
  query getClients {
    clients {
      client_uuid
      name
      key
      ga_tracking_id
      header_logo
      webhooks {
        input_id
        gateway_url
        destination_id
      }
      template_theme {
        fonts {
          font_family_default
          font_family_bold
          font_family_demibold
          heading1_type_size
          heading1_line_height
          heading1_letter_spacing
          heading2_type_size
          heading2_line_height
          heading2_letter_spacing
          heading3_type_size
          heading3_line_height
          heading3_letter_spacing
          heading4_type_size
          heading4_line_height
          heading4_letter_spacing
          paragraph_type_size
          paragraph_line_height
          paragraph_letter_spacing
          body_type_size
          body_line_height
          body_letter_spacing
          body_small_type_size
          body_small_line_height
          body_small_letter_spacing
          caption_type_size
          caption_line_height
          caption_letter_spacing
          font_heading
          font_body
        }
        colors {
          primary_color
          primary_text_color
          secondary_color
          secondary_text_color
          accent1_color
          accent1_text_color
          accent2_color
          accent2_text_color
          default_bg_color
          default_text_color
          button_primary_color
          button_text_color
          recycle_hero_bg_color
          recycle_hero_icon_color
          hero_bg_color
          how_it_works_bg_color
          how_it_works_text_color
          description_bg_color
          super_circle_branding_bg_color
          super_circle_branding_text_color
          landing_button_color
          landing_button_text_color
          bg_primary_color
          bg_secondary_color
          bg_tertiary_color
          bg_brand_color
          bg_accent1_color
          bg_accent2_color
          type_primary_color
          type_secondary_color
          type_tertiary_color
          action_primary_color
          action_secondary_color
          action_links_color
        }
        buttons {
          primary_button_color
          primary_button_stroke
          primary_button_text
          secondary_button_color
          secondary_button_stroke
          secondary_button_text
          is_button_rectangle
        }
      }
      created_at
      created_at_iso
      updated_at
      updated_at_iso
    }
  }
`;

export const GET_CLIENT = gql`
  query getClient($client_uuid: ID) {
    client(client_uuid: $client_uuid) {
      client_uuid
      name
      key
      ga_tracking_id
      header_logo
      webhooks {
        input_id
        gateway_url
        destination_id
      }
      template_theme {
        fonts {
          font_family_default
          font_family_bold
          font_family_demibold
          heading1_type_size
          heading1_line_height
          heading1_letter_spacing
          heading2_type_size
          heading2_line_height
          heading2_letter_spacing
          heading3_type_size
          heading3_line_height
          heading3_letter_spacing
          heading4_type_size
          heading4_line_height
          heading4_letter_spacing
          paragraph_type_size
          paragraph_line_height
          paragraph_letter_spacing
          body_type_size
          body_line_height
          body_letter_spacing
          body_small_type_size
          body_small_line_height
          body_small_letter_spacing
          caption_type_size
          caption_line_height
          caption_letter_spacing
          font_heading
          font_body
        }
        colors {
          primary_color
          primary_text_color
          secondary_color
          secondary_text_color
          accent1_color
          accent1_text_color
          accent2_color
          accent2_text_color
          default_bg_color
          default_text_color
          button_primary_color
          button_text_color
          recycle_hero_bg_color
          recycle_hero_icon_color
          hero_bg_color
          how_it_works_bg_color
          how_it_works_text_color
          description_bg_color
          super_circle_branding_bg_color
          super_circle_branding_text_color
          landing_button_color
          landing_button_text_color
          bg_primary_color
          bg_secondary_color
          bg_tertiary_color
          bg_brand_color
          bg_accent1_color
          bg_accent2_color
          type_primary_color
          type_secondary_color
          type_tertiary_color
          action_primary_color
          action_secondary_color
          action_links_color
        }
        buttons {
          primary_button_color
          primary_button_stroke
          primary_button_text
          secondary_button_color
          secondary_button_stroke
          secondary_button_text
          is_button_rectangle
        }
      }
      created_at
      created_at_iso
      updated_at
      updated_at_iso
    }
  }
`;
