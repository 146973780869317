import { createContext, ReactNode, useEffect, useReducer } from "react";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import {
  FirebaseAuthContextType,
  ActionMap,
  AuthState,
  AuthUser,
} from "../types/auth";
import { firebaseConfig } from "../config";

const INITIALIZE = "INITIALIZE";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  if (process.env.REACT_APP_ENV === "development") {
    firebase.auth().useEmulator("http://localhost:9099");
  }
}

const initialState: AuthState = {
  isAuthenticated: true,
  isInitialized: false,
  user: null,
};

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
};

type FirebaseActions =
  ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>];

const reducer = (state: AuthState, action: FirebaseActions) => {
  if (action.type === INITIALIZE) {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext<FirebaseAuthContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(
    () =>
      firebase.auth().onIdTokenChanged(async (user) => {
        try {
          if (user) {
            dispatch({
              type: INITIALIZE,
              payload: { isAuthenticated: true, user },
            });
            user.getIdToken().then((token) => {
              sessionStorage.setItem("token", token);
            });
          } else {
            dispatch({
              type: INITIALIZE,
              payload: { isAuthenticated: false, user: null },
            });
          }
        } catch (error) {
          // Most probably a connection error. Handle appropriately.
          console.log("Error: " + error);
        }
      }),
    [dispatch]
  );

  const signIn = async (email: string, password: string) =>
    await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((data) => {
        if (data && data.user) {
          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated: true, user: data.user },
          });
          data.user.getIdToken().then((token) => {
            sessionStorage.setItem("token", token);
          });
        }
      });

  const signOut = async () => {
    await firebase.auth().signOut();
    dispatch({
      type: INITIALIZE,
      payload: { isAuthenticated: false, user: null },
    });
  };

  const resetPassword = async (email: string) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const auth = { ...state.user };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "firebase",
        user: {
          id: auth.uid,
          email: auth.email,
          avatar: auth.avatar || "",
          displayName: auth.displayName || "",
          role: "user",
        },
        signIn,
        signOut,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
