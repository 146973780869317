import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { GET_USERS } from "src/pages/admins/graphql/queries.graphql";
import { UPDATE_ADMIN_STATUS } from "src/pages/admins/graphql/mutations.graphql";
import { useAdmin } from "src/contexts/AdminContext";

const TableLoading = () => (
  <Box
    sx={{
      width: "100%",
      padding: "5rem",
      display: "flex",
      justifyContent: "center",
    }}
  >
    <CircularProgress />
  </Box>
);

const AdminsTable = () => {
  const { setRefreshFunction, refreshAdminList } = useAdmin();

  const { loading, data, refetch } = useQuery(GET_USERS, {
    variables: {
      filters: { is_admin: true },
    },
  });

  useEffect(() => {
    if (refetch && !refreshAdminList) {
      setRefreshFunction(refetch);
    }
  }, [refetch, setRefreshFunction, refreshAdminList]);

  const { users } = data || {};
  const [selectedId, setSelectedId] = useState(null);

  const [updateAdminStatus, { data: updateData, loading: updateLoading }] =
    useMutation(UPDATE_ADMIN_STATUS);

  const removeAdmin = () =>
    updateAdminStatus({
      variables: { user_uuid: selectedId, is_admin: false },
      update: () => setSelectedId(null),
      refetchQueries: [GET_USERS],
    });

  const showConfirmationDialog = useMemo(
    () => Boolean(selectedId),
    [selectedId]
  );

  const showSnackBar = useMemo(() => {
    return updateData?.upsertUser?.status === "Success";
  }, [updateData]);

  return (
    <>
      <TableContainer>
        {!loading && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.map((user: any) => (
                <TableRow key={user.user_uuid}>
                  <TableCell>
                    {user.first_name} {user.last_name}
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    <Button
                      variant="text"
                      color="error"
                      onClick={() => setSelectedId(user.user_uuid)}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {loading && <TableLoading />}
      </TableContainer>
      <Dialog open={showConfirmationDialog} onClose={() => setSelectedId(null)}>
        <DialogTitle>Remove Admin</DialogTitle>
        <DialogContent>
          Are you sure you want to remove this user from admins?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setSelectedId(null)}
            color="secondary"
            disabled={updateLoading}
          >
            Cancel
          </Button>
          <Button onClick={removeAdmin} color="error" disabled={updateLoading}>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showSnackBar}
        autoHideDuration={5000}
        message="User has been removed from admins successfully."
      />
    </>
  );
};

export default AdminsTable;
