import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query getUsers($filters: UserFilterInput, $search: String) {
    users(filters: $filters, search: $search) {
      user_uuid
      email
      first_name
      last_name
      is_admin
      created_at
      created_at_iso
      updated_at
      updated_at_iso
    }
  }
`;
