import { SidebarItemsType } from "../../types/sidebar";

import { Briefcase, Home, Truck, User, Users } from "react-feather";

const pagesSection = [
  {
    href: "/dashboard",
    icon: Home,
    title: "Dashboard",
  },
  {
    href: "/shipments",
    icon: Truck,
    title: "Recycle Shipments",
  },
  {
    href: "/admin",
    icon: User,
    title: "Admins",
  },
  {
    href: "/users",
    icon: Users,
    title: "Users",
  },
  {
    href: "/clients",
    icon: Briefcase,
    title: "Clients",
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: "Pages",
    pages: pagesSection,
  },
];

export default navItems;
