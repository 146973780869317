import { gql } from "@apollo/client";

export const UPDATE_CLIENT = gql`
  mutation upsertClient(
    $key: String
    $ga_tracking_id: String
    $header_logo: String
    $template_theme: TemplateThemeInput
  ) {
    upsertClient(
      key: $key
      ga_tracking_id: $ga_tracking_id
      header_logo: $header_logo
      template_theme: $template_theme
    ) {
      status
      message
    }
  }
`;
