import { gql } from "@apollo/client";

export const UPDATE_ADMIN_STATUS = gql`
  mutation updateAdminStatus($user_uuid: String, $is_admin: Boolean) {
    upsertUser(user_uuid: $user_uuid, is_admin: $is_admin) {
      status
      message
    }
  }
`;
