import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CLIENT } from "src/pages/clients/graphql/queries.graphql";
import { UPDATE_CLIENT } from "src/pages/clients/graphql/mutations.graphql";
import { useParams } from "react-router";
import { Check, Edit, X } from "react-feather";

type ClientAttributeProps = {
  label: string;
  value: any;
  editable?: boolean;
  isLogo?: boolean;
  onUpdate?: (value: any) => void;
};

const ClientAttribute: React.FC<ClientAttributeProps> = ({
  label,
  value,
  editable = false,
  isLogo = false,
  onUpdate,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState<any>(value);

  const changeValue = () => {
    if (onUpdate) {
      onUpdate(inputValue);
    }
    setIsEditing(false);
  };

  const setOldValue = () => {
    setInputValue(value);
    setIsEditing(false);
  };

  return (
    <>
      <Grid item sm={2}>
        <Typography
          align="right"
          sx={{ fontWeight: "bold", padding: "10px 0 0" }}
        >
          {label}:&nbsp;
        </Typography>
      </Grid>
      <Grid item sm={4} sx={{ position: "relative" }}>
        {!isEditing && !isLogo && (
          <Typography sx={{ padding: "10px 0 0" }}>{value}</Typography>
        )}
        {!isEditing && isLogo && (
          <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
            <img src={value} alt="Client logo" width="150" />
          </Box>
        )}
        {isEditing && (
          <TextField
            size="small"
            variant="outlined"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        )}
        {editable && (
          <Box sx={{ position: "absolute", right: 0, top: 7 }}>
            {!isEditing && (
              <Edit
                onClick={() => setIsEditing(true)}
                style={{ width: "14px", cursor: "pointer" }}
              />
            )}
            {isEditing && (
              <>
                <Check
                  color="green"
                  onClick={changeValue}
                  style={{ width: "14px", cursor: "pointer" }}
                />
                <X
                  color="red"
                  onClick={setOldValue}
                  style={{
                    width: "14px",
                    cursor: "pointer",
                    marginLeft: "5px",
                  }}
                />
              </>
            )}
          </Box>
        )}
      </Grid>
    </>
  );
};

const ClientDetail: React.FC = () => {
  const params = useParams();
  const { client_uuid } = params;

  const [client, setClient] = useState<any>(null);

  const { data } = useQuery(GET_CLIENT, {
    variables: { client_uuid },
    skip: !client_uuid,
  });
  if (data && !client) {
    setClient(data?.client);
  }

  const [upsertClient, { data: updateData }] = useMutation(UPDATE_CLIENT);

  const updateFont = (attribute: string, value: string) => {
    return {
      ...client,
      template_theme: {
        ...client?.template_theme,
        fonts: {
          ...client?.template_theme?.fonts,
          [attribute]: value,
        },
      },
    };
  };

  const updateColor = (attribute: string, value: string) => {
    return {
      ...client,
      template_theme: {
        ...client?.template_theme,
        colors: {
          ...client?.template_theme?.colors,
          [attribute]: value,
        },
      },
    };
  };

  const getFormattedName = (attribute: string) => {
    const spaced = attribute.replace(/_/g, " ");
    return spaced.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const colors = useMemo(() => {
    const themeColors = { ...client?.template_theme?.colors };
    delete themeColors.__typename;
    return themeColors;
  }, [client]);

  const saveClient = () => {
    const fonts = { ...client.template_theme?.fonts };
    delete fonts.__typename;

    const colors = { ...client.template_theme?.colors };
    delete colors.__typename;

    upsertClient({
      variables: {
        key: client.key,
        ga_tracking_id: client.ga_tracking_id,
        header_logo: client.header_logo,
        template_theme: {
          fonts,
          colors,
        },
      },
    });
  };

  const showSnackBar = useMemo(() => {
    return updateData?.upsertUser?.status === "Success";
  }, [updateData]);

  return (
    <>
      <Grid container spacing={1} columns={12}>
        <Grid item sm={12}>
          <Typography variant="h1">{client?.name}</Typography>
        </Grid>
        <Grid item sm={12}>
          <Card sx={{ p: 5 }}>
            <Grid container columnSpacing={3}>
              <ClientAttribute
                label="Header Logo"
                value={client?.header_logo}
                editable
                isLogo
                onUpdate={(header_logo) =>
                  setClient({ ...client, header_logo })
                }
              />
              <ClientAttribute
                label="GA Tracking ID"
                value={client?.ga_tracking_id || "N/A"}
                editable
                onUpdate={(ga_tracking_id) =>
                  setClient({ ...client, ga_tracking_id })
                }
              />
            </Grid>
            <Typography variant="h3" sx={{ marginTop: "32px" }}>
              Template Theme Variables
            </Typography>

            <Typography variant="h4" sx={{ marginTop: "16px" }}>
              Fonts
            </Typography>
            <Grid container columnSpacing={3}>
              <ClientAttribute
                label="Font Family Default"
                value={client?.template_theme?.fonts.font_family_default}
                editable
                onUpdate={(font_family_default) =>
                  setClient(
                    updateFont("font_family_default", font_family_default)
                  )
                }
              />
              <ClientAttribute
                label="Font Family Bold"
                value={client?.template_theme?.fonts.font_family_bold}
                editable
                onUpdate={(font_family_bold) =>
                  setClient(updateFont("font_family_bold", font_family_bold))
                }
              />
              <ClientAttribute
                label="Font Family Semibold"
                value={client?.template_theme?.fonts.font_family_demibold}
                editable
                onUpdate={(font_family_demibold) =>
                  setClient(
                    updateFont("font_family_demibold", font_family_demibold)
                  )
                }
              />
            </Grid>

            <Typography variant="h4" sx={{ marginTop: "16px" }}>
              Colors
            </Typography>
            <Grid container columnSpacing={3}>
              {Object.keys(colors).map((color) => (
                <ClientAttribute
                  label={getFormattedName(color)}
                  value={colors[color]}
                  editable
                  key={color}
                  onUpdate={(value) => setClient(updateColor(color, value))}
                />
              ))}
            </Grid>

            <Typography variant="h3" sx={{ marginTop: "32px" }}>
              Webhooks
            </Typography>
            <Grid container columnSpacing={3}>
              <ClientAttribute
                label="Input ID"
                value={client?.webhooks?.input_id}
              />
              <ClientAttribute
                label="Gateway URL"
                value={client?.webhooks?.gateway_url}
              />
              <ClientAttribute
                label="Destination ID"
                value={client?.webhooks?.destination_id}
              />
            </Grid>
            <Grid
              item
              sm={12}
              sx={{
                marginTop: "32px",
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <Button variant="contained" onClick={saveClient}>
                Save
              </Button>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        open={showSnackBar}
        autoHideDuration={5000}
        message="User has been removed from admins successfully."
      />
    </>
  );
};

export default ClientDetail;
