import { useEffect, useState, useMemo } from "react";
import {
  Autocomplete,
  Box,
  Modal,
  Typography,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import { SxProps } from "@mui/system";
import { GET_USERS } from "src/pages/admins/graphql/queries.graphql";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_ADMIN_STATUS } from "src/pages/admins/graphql/mutations.graphql";
import { useAdmin } from "src/contexts/AdminContext";

type AddAdminModalProps = {
  toggleModal: boolean;
  setToggleModal: (shouldOpen: boolean) => void;
};

type SelectedUser = {
  label: string;
  value: string;
};

const style: SxProps = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 6,
};

const AddAdminModal = ({ toggleModal, setToggleModal }: AddAdminModalProps) => {
  const { refreshAdminList } = useAdmin();

  const [search, setSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState<SelectedUser | null>(null);

  const { data } = useQuery(GET_USERS, {
    variables: { search, filters: { is_admin: false } },
  });
  const { users } = data || {};

  const userOptions = useMemo(() => {
    if (users?.length) {
      return users.map((user: any) => ({
        label: `${user.first_name} ${user.last_name} (${user.email})`,
        value: user.user_uuid,
      }));
    }
    return [];
  }, [users]);

  const [updateAdminStatus, { data: updateData, loading: updateLoading }] =
    useMutation(UPDATE_ADMIN_STATUS);

  useEffect(() => {
    if (updateData?.upsertUser?.status === "Success" && refreshAdminList) {
      refreshAdminList();
    }
  }, [updateData, refreshAdminList]);

  const addAdmin = () =>
    updateAdminStatus({
      variables: { user_uuid: selectedUser?.value, is_admin: true },
    });

  return (
    <Modal open={toggleModal} onClose={() => setToggleModal(false)}>
      <Box sx={style}>
        <Typography variant="h1" sx={{ mb: 4 }}>
          Add Admin
        </Typography>
        <Typography variant="body1">
          Search and select a user to assign as admin.
        </Typography>
        <Autocomplete
          options={userOptions}
          inputValue={search}
          onInputChange={(event, newInputValue) => setSearch(newInputValue)}
          value={selectedUser}
          onChange={(event, newValue) => setSelectedUser(newValue)}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Search Users" />
          )}
          sx={{ my: 4 }}
        />
        <Box sx={{ direction: "rtl" }}>
          <Button
            variant="contained"
            size="large"
            onClick={addAdmin}
            disabled={!selectedUser || updateLoading}
          >
            Add
          </Button>
        </Box>
        {updateData?.upsertUser?.status === "Success" && (
          <Alert severity="success" color="success" sx={{ mt: 4 }}>
            User successfully updated as admin.
          </Alert>
        )}
      </Box>
    </Modal>
  );
};

export default AddAdminModal;
