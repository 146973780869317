/* eslint-disable react-hooks/rules-of-hooks */
import { useLazyQuery, useQuery } from "@apollo/client";

import { GET_SELF, GET_USERS_DATA } from "../utils/graphql/users.gql";

const useGetUsers = () => {
  const { loading, error, data } = useQuery<any, any>(GET_USERS_DATA);

  return {
    loading,
    error,
    data,
  };
};

export const useGetSelf = () => {
  const [
    loadLazyQuery,
    { loading: selfLoading, error: selfError, data: selfData },
  ] = useLazyQuery<any, any>(GET_SELF, { fetchPolicy: "network-only" });

  return {
    loadLazyQuery,
    loading: selfLoading,
    error: selfError,
    data: selfData,
  };
};

export default useGetUsers;
