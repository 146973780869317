import { ApolloQueryResult } from "@apollo/client";
import { createContext, useContext, useReducer } from "react";

type AdminStateType = {
  refreshAdminList?: Function | null;
};

type AdminContextType = {
  setRefreshFunction: Function;
} & AdminStateType;

const SET_REFRESH = "SET_REFRESH";

const reducer = (
  state: AdminStateType,
  action: {
    type: string;
    payload: any;
  }
) => {
  if (action.type === SET_REFRESH) {
    return {
      ...state,
      refreshAdminList: action.payload,
    };
  }
  return { ...state };
};

const AdminContext = createContext<AdminContextType | null>(null);

export function useAdmin() {
  const value = useContext(AdminContext);

  if (!value) {
    throw new Error(
      "Must wrap component in AdminContextProvider to use useAdmin hook"
    );
  }

  return value;
}

const initialState = {
  refreshAdminList: null,
};

const AdminContextProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setRefreshFunction = (refresh: Promise<ApolloQueryResult<any>>) => {
    console.log(refresh);
    dispatch({
      type: SET_REFRESH,
      payload: refresh,
    });
  };

  return (
    <AdminContext.Provider value={{ ...state, setRefreshFunction }}>
      {children}
    </AdminContext.Provider>
  );
};

export default AdminContextProvider;
