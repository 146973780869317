let devFirebaseConfig = {
  apiKey: "AIzaSyAg_Qi7szPS4MneipzD-ljbhNDd_FxxM-Q",
  authDomain: "thousandfell-stage.firebaseapp.com",
  projectId: "thousandfell-stage",
  storageBucket: "thousandfell-stage.appspot.com",
  messagingSenderId: "396061800289",
  appId: "1:396061800289:web:f22246dd22f46d58b13720",
  measurementId: "G-R42KQMNRWQ",
};

let devGraphQRL = "http://localhost:4001/admin/graphql";
let devShipmentsCsv = "http://localhost:4001/admin/recycle-shipments-csv";

let sprintFirebaseConfig = {
  apiKey: "AIzaSyC0FtkZy2PlxGrvzpEcWPtiaXHPN-9bd-I",
  authDomain: "thousandfell-sprint.firebaseapp.com",
  projectId: "thousandfell-sprint",
  storageBucket: "thousandfell-sprint.appspot.com",
  messagingSenderId: "742210176052",
  appId: "1:742210176052:web:01bbd77c7768ccc6433661",
  measurementId: "G-G60Q1N25JM",
};

let sprintGraphQRL = "https://api-sprint.supercircle.world/admin/graphql";
let sprintShipmentsCsv =
  "https://api-sprint.supercircle.world/admin/recycle-shipments-csv";

let stageFirebaseConfig = {
  apiKey: "AIzaSyAg_Qi7szPS4MneipzD-ljbhNDd_FxxM-Q",
  authDomain: "thousandfell-stage.firebaseapp.com",
  projectId: "thousandfell-stage",
  storageBucket: "thousandfell-stage.appspot.com",
  messagingSenderId: "396061800289",
  appId: "1:396061800289:web:f22246dd22f46d58b13720",
  measurementId: "G-R42KQMNRWQ",
};

let stageGraphQRL = "https://api-stage.supercircle.world/admin/graphql";
let stageShipmentsCsv =
  "https://api-stage.supercircle.world/admin/recycle-shipments-csv";

let demoFirebaseConfig = {
  apiKey: "AIzaSyByYsXTHzIOVeWYCXdspfZ802464rsDNQA",
  authDomain: "thousandfell-demo.firebaseapp.com",
  projectId: "thousandfell-demo",
  storageBucket: "thousandfell-demo.appspot.com",
  messagingSenderId: "846151414999",
  appId: "1:846151414999:web:5ee7da9f8b187ab8186c92",
  measurementId: "G-TC1MHH8QG6",
};

let demoGraphQRL = "https://api-demo.supercircle.world/admin/graphql";
let demoShipmentsCsv =
  "https://api-demo.supercircle.world/admin/recycle-shipments-csv";

let prodFirebaseConfig = {
  apiKey: "AIzaSyB176KO-7tY1H0j-t4dl887VazIhKJV1a8",
  authDomain: "thousandfell-prod.firebaseapp.com",
  projectId: "thousandfell-prod",
  storageBucket: "thousandfell-prod.appspot.com",
  messagingSenderId: "985350110308",
  appId: "1:985350110308:web:7333dc00b685fae1b0b371",
  measurementId: "G-2YCXHNQH9C",
};

let prodGraphQRL = "https://api.supercircle.world/admin/graphql";
let prodShipmentsCsv =
  "https://api.supercircle.world/admin/recycle-shipments-csv";

let curFirebaseConfig = null;
let curAppGraphQRL = null;
let curAppShipmentsCSV = null;

if (process.env.REACT_APP_ENV === "production") {
  curFirebaseConfig = prodFirebaseConfig;
  curAppGraphQRL = prodGraphQRL;
  curAppShipmentsCSV = prodShipmentsCsv;
} else if (process.env.REACT_APP_ENV === "demo") {
  curFirebaseConfig = demoFirebaseConfig;
  curAppGraphQRL = demoGraphQRL;
  curAppShipmentsCSV = demoShipmentsCsv;
} else if (process.env.REACT_APP_ENV === "stage") {
  curFirebaseConfig = stageFirebaseConfig;
  curAppGraphQRL = stageGraphQRL;
  curAppShipmentsCSV = stageShipmentsCsv;
  curAppShipmentsCSV = demoShipmentsCsv;
} else if (process.env.REACT_APP_ENV === "sprint") {
  curFirebaseConfig = sprintFirebaseConfig;
  curAppGraphQRL = sprintGraphQRL;
  curAppShipmentsCSV = sprintShipmentsCsv;
} else {
  curFirebaseConfig = devFirebaseConfig;
  curAppGraphQRL = devGraphQRL;
  curAppShipmentsCSV = devShipmentsCsv;
}

export const firebaseConfig = curFirebaseConfig;
export const appConfig = {
  graphqrl: process.env.REACT_APP_GRAPHQRL || curAppGraphQRL,
  shipmentCsv: process.env.REACT_APP_SHIPMENTCSV || curAppShipmentsCSV,
};
