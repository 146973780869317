import async from "./components/Async";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";
import AdminList from "./pages/admins/AdminList";
import ClientList from "./pages/clients/ClientList";
import ClientDetail from "./pages/clients/ClientDetail";

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));
const RecycleShipments = async(
  () => import("./pages/dashboards/RecycleShipments")
);
const DetailedRecycleShipments = async(
  () => import("./pages/dashboards/RecycleShipments/DetailedRecycleShipments")
);
const Users = async(() => import("./pages/dashboards/Users"));
const DetailedUsers = async(
  () => import("./pages/dashboards/Users/DetailedUsers")
);

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Default />,
      },
      {
        path: "dashboard",
        element: <Default />,
      },
      {
        path: "shipments",
        element: <RecycleShipments />,
      },
      {
        path: "shipments/detail/:id",
        element: <DetailedRecycleShipments />,
      },
      {
        path: "admin",
        children: [
          {
            path: "",
            element: <AdminList />,
          },
        ],
      },
      {
        path: "clients",
        children: [
          {
            path: "",
            element: <ClientList />,
          },
          {
            path: ":client_uuid",
            element: <ClientDetail />,
          },
        ],
      },
    ],
  },
  {
    path: "users",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Users />,
      },
      {
        path: "detail/:id",
        element: <DetailedUsers />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
];

export default routes;
