import React from "react";
import { Card, Grid, Typography } from "@mui/material";
import ClientsTable from "./ClientsTable";

const AdminList: React.FC = () => {
  return (
    <Grid container spacing={1} columns={12}>
      <Grid item sm={12}>
        <Typography variant="h1">Clients</Typography>
      </Grid>
      <Grid item sm={12}>
        <Card sx={{ p: 5 }}>
          <ClientsTable />
        </Card>
      </Grid>
    </Grid>
  );
};

export default AdminList;
