import { gql } from "@apollo/client";

export const GET_USERS_DATA = gql`
  query users {
    users {
      user_uuid
      email
      first_name
      last_name
      created_at
      created_at_iso
    }
  }
`;

export const GET_USER_DATA = gql`
  query users($user_uuid: ID!) {
    user(user_uuid: $user_uuid) {
      user_uuid
      email
      first_name
      last_name
      created_at
      created_at_iso
      updated_at
      updated_at_iso
      product_units {
        product_unit_uuid
        product_sku_uuid
        user_uuid
        unit_code
        recycle_state
        recycle_shipment_uuid
        product_sku {
          product_sku_uuid
          product_uuid
          key
          color
          size
          image
          credit_amount
        }
      }
      recycle_shipments {
        recycle_shipment_uuid
        user_uuid
        product_unit_uuids
        recycle_state
        tracking_number
      }
    }
  }
`;

export const GET_SELF = gql`
  query self {
    self {
      user_uuid
      is_admin
    }
  }
`;
