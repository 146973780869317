import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
  Divider as MuiDivider,
} from "@mui/material";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import AdminsTable from "./AdminsTable";

import AddAdminModal from "./AddAdminModal";
import AdminContextProvider from "../../../contexts/AdminContext";
import Breadcrumbs from "src/components/breadcrumbs/Breadcrumbs";

const Divider = styled(MuiDivider)(spacing);

const AdminList: React.FC = () => {
  const [toggleModal, setToggleModal] = useState<boolean>(false);

  return (
    <AdminContextProvider>
      <Grid container spacing={1} columns={12}>
        <Grid item sm={12}>
          <Typography variant="h1">Admins</Typography>
          <Breadcrumbs />
          <Divider my={6} />
        </Grid>

        <Grid item sm={12}>
          <Card sx={{ p: 5 }}>
            <Box sx={{ direction: "rtl", mb: 5 }}>
              <Button onClick={() => setToggleModal(true)} variant="contained">
                Add Admin
              </Button>
            </Box>
            <AdminsTable />
          </Card>
        </Grid>
      </Grid>
      <AddAdminModal
        toggleModal={toggleModal}
        setToggleModal={setToggleModal}
      />
    </AdminContextProvider>
  );
};

export default AdminList;
